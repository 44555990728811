export default {
  list: {
    headers: {
      id: "ID",
      name: "Nazwa ścieżki",
      nameAtHome: "Nazwa ścieżki na /home",
      activeTo: "Aktywna do",
      series: "Przypisane serie",
      company: "Firma",
      language: "Wersja",
    },
    filters: {
      series: "Przypisane serie",
      company: "Firma",
      activeTo: "Data zakończenia",
      from: "od",
      to: "do",
    },
  },
  form: {
    name: "Nazwa ścieżki",
    nameAtHome: "Nazwa ścieżki na /home",
    description: "Opis ścieżki",
    slug: "Slug",
    slugDescription:
      "Ustal unikalny ciąg znaków, który znajdziemy zazwyczaj po ukośniku w adresie URL podstrony.",
    slogan: "Hasło ścieżki",
    addToCompany: "Dodaj do firmy",
    optional: "opcjonalnie",
    noCompany: "Brak firmy",
    active: "Aktywny",
    changeRequest: "Wymuś zmianę",
    language: "Wybierz język",
    tags: "Tagi",
    enterToSave: "Naciśnij Enter, aby zapisać tag",
    dateTo: "Wybierz datę ważności ścieżki",
    b2c: "Ścieżka dla B2C",
    certificate: "Certyfikat",
    certificateFile: "grafikę certyfikatu",
    certificateDescription: "Opis certyfikatu",
    series: "Serie",
    seriesDescription: "Najpierw wybierz język żeby wybrać serie",
    tagExists: "Taki tag został już dodany",
    addPath: "Dodaj ścieżkę",
  },
};
